import iziToast from 'izitoast';

const defaultConfig = {
  id: null,
  class: '',
  zindex: null,
  layout: 1,
  balloon: false,
  close: true,
  closeOnEscape: false,
  closeOnClick: false,
  target: '',
  targetFirst: true,
  timeout: 5000,
  rtl: false,
  animateInside: true,
  drag: true,
  pauseOnHover: true,
  resetOnHover: false,
  transitionIn: 'fadeInUp',
  transitionOut: 'fadeOut',
  transitionInMobile: 'fadeInUp',
  transitionOutMobile: 'fadeOutDown',

  // appearance
  theme: 'dark', // dark,light
  color: '', // blue, red, green, yellow
  backgroundColor: '',
  icon: 'icon-person',
  iconText: '',
  iconColor: '',
  iconUrl: null,
  position: 'topCenter', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
  progressBar: true,
  progressBarColor: 'rgb(211,217,215)',
  progressBarEasing: 'linear',
  image: '',
  imageWidth: 50,
  maxWidth: null,
  displayMode: 0, // once, replace
  overlay: false,
  overlayClose: false,
  overlayColor: 'rgba(0, 0, 0, 0.6)',
  buttons: {},
  inputs: {},

  // content
  title: '',
  titleColor: '',
  titleSize: '',
  titleLineHeight: '',

  message: '',
  messageColor: '',
  messageSize: '',
  messageLineHeight: '',

  // events
  onOpening: (instance, toast) => {
  },
  onOpened: (instance, toast) => {
  },
  onClosing: (instance, toast, closed) => {
  },
  onClosed: (instance, toast, closed) => {
  }
};

const toaster = iziToast;
toaster.settings(defaultConfig);

/**
 *
 * @param {{
 *   id: null,
 *   class: '',
 *   zindex: null,
 *   layout: 1,
 *   balloon: false,
 *   close: true,
 *   closeOnEscape: false,
 *   closeOnClick: false,
 *   target: '',
 *   targetFirst: true,
 *   timeout: 5000,
 *   rtl: false,
 *   animateInside: true,
 *   drag: true,
 *   pauseOnHover: true,
 *   resetOnHover: false,
 *   transitionIn: 'fadeInUp',
 *   transitionOut: 'fadeOut',
 *   transitionInMobile: 'fadeInUp',
 *   transitionOutMobile: 'fadeOutDown',
 *   theme: 'dark'|'light',
 *   color: ''|'blue'|'red'|'green'|'yellow',
 *   backgroundColor: '',
 *   icon: 'icon-person',
 *   iconText: '',
 *   iconColor: '',
 *   iconUrl: null,
 *   position: 'topCenter'|'bottomRight'|'bottomLeft'|'topRight'|'topLeft'|'topCenter'|'bottomCenter',
 *   progressBar: true,
 *   progressBarColor: 'rgb(0, 255, 184)',
 *   progressBarEasing: 'linear',
 *   image: '',
 *   imageWidth: 50,
 *   maxWidth: null,
 *   displayMode: 0,
 *   overlay: false,
 *   overlayClose: false,
 *   overlayColor: 'rgba(0, 0, 0, 0.6)',
 *   buttons: {},
 *   inputs: {},
 *   title: '',
 *   titleColor: '',
 *   titleSize: '',
 *   titleLineHeight: '',
 *   message: '',
 *   messageColor: '',
 *   messageSize: '',
 *   messageLineHeight: '',
 *   onOpening: (instance, toast) => {},
 *   onOpened: (instance, toast) => {},
 *   onClosing: (instance, toast, closed) => {},
 *   onClosed: (instance, toast, closed) => {}
 * }} config
 */
const success = (config) => toaster.success({
  ...defaultConfig,
  class: 'toast-body bg-success',
  icon: 'icon-check-circle',
  messageColor: 'rgb(247,253,249)',
  ...config,
});

/**
 *
 * @param {{
 *   id: null,
 *   class: '',
 *   zindex: null,
 *   layout: 1,
 *   balloon: false,
 *   close: true,
 *   closeOnEscape: false,
 *   closeOnClick: false,
 *   target: '',
 *   targetFirst: true,
 *   timeout: 5000,
 *   rtl: false,
 *   animateInside: true,
 *   drag: true,
 *   pauseOnHover: true,
 *   resetOnHover: false,
 *   transitionIn: 'fadeInUp',
 *   transitionOut: 'fadeOut',
 *   transitionInMobile: 'fadeInUp',
 *   transitionOutMobile: 'fadeOutDown',
 *   theme: 'dark'|'light',
 *   color: ''|'blue'|'red'|'green'|'yellow',
 *   backgroundColor: '',
 *   icon: 'icon-person',
 *   iconText: '',
 *   iconColor: '',
 *   iconUrl: null,
 *   position: 'topCenter'|'bottomRight'|'bottomLeft'|'topRight'|'topLeft'|'topCenter'|'bottomCenter',
 *   progressBar: true,
 *   progressBarColor: 'rgb(0, 255, 184)',
 *   progressBarEasing: 'linear',
 *   image: '',
 *   imageWidth: 50,
 *   maxWidth: null,
 *   displayMode: 0,
 *   overlay: false,
 *   overlayClose: false,
 *   overlayColor: 'rgba(0, 0, 0, 0.6)',
 *   buttons: {},
 *   inputs: {},
 *   title: '',
 *   titleColor: '',
 *   titleSize: '',
 *   titleLineHeight: '',
 *   message: '',
 *   messageColor: '',
 *   messageSize: '',
 *   messageLineHeight: '',
 *   onOpening: (instance, toast) => {},
 *   onOpened: (instance, toast) => {},
 *   onClosing: (instance, toast, closed) => {},
 *   onClosed: (instance, toast, closed) => {}
 * }} config
 */
const successAlt = (config) => toaster.success({
  ...defaultConfig,
  class: 'toast-body',
  color: 'green',
  icon: 'icon-check',
  iconColor: '#2e323c',
  titleColor: '#2e323c',
  messageColor: '#3c4049',
  ...config,
});

/**
 *
 * @param {{
 *   id: null,
 *   class: '',
 *   zindex: null,
 *   layout: 1,
 *   balloon: false,
 *   close: true,
 *   closeOnEscape: false,
 *   closeOnClick: false,
 *   target: '',
 *   targetFirst: true,
 *   timeout: 5000,
 *   rtl: false,
 *   animateInside: true,
 *   drag: true,
 *   pauseOnHover: true,
 *   resetOnHover: false,
 *   transitionIn: 'fadeInUp',
 *   transitionOut: 'fadeOut',
 *   transitionInMobile: 'fadeInUp',
 *   transitionOutMobile: 'fadeOutDown',
 *   theme: 'dark'|'light',
 *   color: ''|'blue'|'red'|'green'|'yellow',
 *   backgroundColor: '',
 *   icon: 'icon-person',
 *   iconText: '',
 *   iconColor: '',
 *   iconUrl: null,
 *   position: 'topCenter'|'bottomRight'|'bottomLeft'|'topRight'|'topLeft'|'topCenter'|'bottomCenter',
 *   progressBar: true,
 *   progressBarColor: 'rgb(0, 255, 184)',
 *   progressBarEasing: 'linear',
 *   image: '',
 *   imageWidth: 50,
 *   maxWidth: null,
 *   displayMode: 0,
 *   overlay: false,
 *   overlayClose: false,
 *   overlayColor: 'rgba(0, 0, 0, 0.6)',
 *   buttons: {},
 *   inputs: {},
 *   title: '',
 *   titleColor: '',
 *   titleSize: '',
 *   titleLineHeight: '',
 *   message: '',
 *   messageColor: '',
 *   messageSize: '',
 *   messageLineHeight: '',
 *   onOpening: (instance, toast) => {},
 *   onOpened: (instance, toast) => {},
 *   onClosing: (instance, toast, closed) => {},
 *   onClosed: (instance, toast, closed) => {}
 * }} config
 */
const info = (config) => toaster.success({
  ...defaultConfig,
  class: 'toast-body',
  icon: 'icon-info-with-circle',
  ...config
});

/**
 *
 * @param {{
 *   id: null,
 *   class: '',
 *   zindex: null,
 *   layout: 1,
 *   balloon: false,
 *   close: true,
 *   closeOnEscape: false,
 *   closeOnClick: false,
 *   target: '',
 *   targetFirst: true,
 *   timeout: 5000,
 *   rtl: false,
 *   animateInside: true,
 *   drag: true,
 *   pauseOnHover: true,
 *   resetOnHover: false,
 *   transitionIn: 'fadeInUp',
 *   transitionOut: 'fadeOut',
 *   transitionInMobile: 'fadeInUp',
 *   transitionOutMobile: 'fadeOutDown',
 *   theme: 'dark'|'light',
 *   color: ''|'blue'|'red'|'green'|'yellow',
 *   backgroundColor: '',
 *   icon: 'icon-person',
 *   iconText: '',
 *   iconColor: '',
 *   iconUrl: null,
 *   position: 'topCenter'|'bottomRight'|'bottomLeft'|'topRight'|'topLeft'|'topCenter'|'bottomCenter',
 *   progressBar: true,
 *   progressBarColor: 'rgb(0, 255, 184)',
 *   progressBarEasing: 'linear',
 *   image: '',
 *   imageWidth: 50,
 *   maxWidth: null,
 *   displayMode: 0,
 *   overlay: false,
 *   overlayClose: false,
 *   overlayColor: 'rgba(0, 0, 0, 0.6)',
 *   buttons: {},
 *   inputs: {},
 *   title: '',
 *   titleColor: '',
 *   titleSize: '',
 *   titleLineHeight: '',
 *   message: '',
 *   messageColor: '',
 *   messageSize: '',
 *   messageLineHeight: '',
 *   onOpening: (instance, toast) => {},
 *   onOpened: (instance, toast) => {},
 *   onClosing: (instance, toast, closed) => {},
 *   onClosed: (instance, toast, closed) => {}
 * }} config
 */
const primary = (config) => toaster.info({
  ...defaultConfig,
  class: 'toast-body',
  icon: 'icon-info',
  backgroundColor: '#007ae1',
  progressBarColor: '#b6d6f1',
  ...config
});

/**
 *
 * @param {{
 *   id: null,
 *   class: '',
 *   zindex: null,
 *   layout: 1,
 *   balloon: false,
 *   close: true,
 *   closeOnEscape: false,
 *   closeOnClick: false,
 *   target: '',
 *   targetFirst: true,
 *   timeout: 5000,
 *   rtl: false,
 *   animateInside: true,
 *   drag: true,
 *   pauseOnHover: true,
 *   resetOnHover: false,
 *   transitionIn: 'fadeInUp',
 *   transitionOut: 'fadeOut',
 *   transitionInMobile: 'fadeInUp',
 *   transitionOutMobile: 'fadeOutDown',
 *   theme: 'dark'|'light',
 *   color: ''|'blue'|'red'|'green'|'yellow',
 *   backgroundColor: '',
 *   icon: 'icon-person',
 *   iconText: '',
 *   iconColor: '',
 *   iconUrl: null,
 *   position: 'topCenter'|'bottomRight'|'bottomLeft'|'topRight'|'topLeft'|'topCenter'|'bottomCenter',
 *   progressBar: true,
 *   progressBarColor: 'rgb(0, 255, 184)',
 *   progressBarEasing: 'linear',
 *   image: '',
 *   imageWidth: 50,
 *   maxWidth: null,
 *   displayMode: 0,
 *   overlay: false,
 *   overlayClose: false,
 *   overlayColor: 'rgba(0, 0, 0, 0.6)',
 *   buttons: {},
 *   inputs: {},
 *   title: '',
 *   titleColor: '',
 *   titleSize: '',
 *   titleLineHeight: '',
 *   message: '',
 *   messageColor: '',
 *   messageSize: '',
 *   messageLineHeight: '',
 *   onOpening: (instance, toast) => {},
 *   onOpened: (instance, toast) => {},
 *   onClosing: (instance, toast, closed) => {},
 *   onClosed: (instance, toast, closed) => {}
 * }} config
 */
const warning = (config) => toaster.warning({
  ...defaultConfig,
  class: 'toast-body bg-warning',
  titleColor: '#2e323c',
  messageColor: '#3c4049',
  progressBarColor: 'rgba(73,71,65,0.7)',
  icon: 'icon-alert-octagon',
  ...config
});

/**
 *
 * @param {{
 *   id: null,
 *   class: '',
 *   zindex: null,
 *   layout: 1,
 *   balloon: false,
 *   close: true,
 *   closeOnEscape: false,
 *   closeOnClick: false,
 *   target: '',
 *   targetFirst: true,
 *   timeout: 5000,
 *   rtl: false,
 *   animateInside: true,
 *   drag: true,
 *   pauseOnHover: true,
 *   resetOnHover: false,
 *   transitionIn: 'fadeInUp',
 *   transitionOut: 'fadeOut',
 *   transitionInMobile: 'fadeInUp',
 *   transitionOutMobile: 'fadeOutDown',
 *   theme: 'dark'|'light',
 *   color: ''|'blue'|'red'|'green'|'yellow',
 *   backgroundColor: '',
 *   icon: 'icon-person',
 *   iconText: '',
 *   iconColor: '',
 *   iconUrl: null,
 *   position: 'topCenter'|'bottomRight'|'bottomLeft'|'topRight'|'topLeft'|'topCenter'|'bottomCenter',
 *   progressBar: true,
 *   progressBarColor: 'rgb(0, 255, 184)',
 *   progressBarEasing: 'linear',
 *   image: '',
 *   imageWidth: 50,
 *   maxWidth: null,
 *   displayMode: 0,
 *   overlay: false,
 *   overlayClose: false,
 *   overlayColor: 'rgba(0, 0, 0, 0.6)',
 *   buttons: {},
 *   inputs: {},
 *   title: '',
 *   titleColor: '',
 *   titleSize: '',
 *   titleLineHeight: '',
 *   message: '',
 *   messageColor: '',
 *   messageSize: '',
 *   messageLineHeight: '',
 *   onOpening: (instance, toast) => {},
 *   onOpened: (instance, toast) => {},
 *   onClosing: (instance, toast, closed) => {},
 *   onClosed: (instance, toast, closed) => {}
 * }} config
 */
const error = (config) => toaster.error({
  ...defaultConfig,
  class: 'toast-body bg-danger',
  icon: 'icon-cancel',
  timeout: 6000,
  messageColor: 'rgba(255,255,255,.85)',
  ...config,
});

/**
 *
 * @param {{
 *   id: null,
 *   class: '',
 *   zindex: null,
 *   layout: 1,
 *   balloon: false,
 *   close: true,
 *   closeOnEscape: false,
 *   closeOnClick: false,
 *   target: '',
 *   targetFirst: true,
 *   timeout: 5000,
 *   rtl: false,
 *   animateInside: true,
 *   drag: true,
 *   pauseOnHover: true,
 *   resetOnHover: false,
 *   transitionIn: 'fadeInUp',
 *   transitionOut: 'fadeOut',
 *   transitionInMobile: 'fadeInUp',
 *   transitionOutMobile: 'fadeOutDown',
 *   theme: 'dark'|'light',
 *   color: ''|'blue'|'red'|'green'|'yellow',
 *   backgroundColor: '',
 *   icon: 'icon-person',
 *   iconText: '',
 *   iconColor: '',
 *   iconUrl: null,
 *   position: 'topCenter'|'bottomRight'|'bottomLeft'|'topRight'|'topLeft'|'topCenter'|'bottomCenter',
 *   progressBar: true,
 *   progressBarColor: 'rgb(0, 255, 184)',
 *   progressBarEasing: 'linear',
 *   image: '',
 *   imageWidth: 50,
 *   maxWidth: null,
 *   displayMode: 0,
 *   overlay: false,
 *   overlayClose: false,
 *   overlayColor: 'rgba(0, 0, 0, 0.6)',
 *   buttons: {},
 *   inputs: {},
 *   title: '',
 *   titleColor: '',
 *   titleSize: '',
 *   titleLineHeight: '',
 *   message: '',
 *   messageColor: '',
 *   messageSize: '',
 *   messageLineHeight: '',
 *   onOpening: (instance, toast) => {},
 *   onOpened: (instance, toast) => {},
 *   onClosing: (instance, toast, closed) => {},
 *   onClosed: (instance, toast, closed) => {}
 * }} config
 * @param actionCb : CallableFunction
 */
const confirmation = (config, actionCb) => toaster.warning({
  ...defaultConfig,
  timeout: 10000,
  position: 'center',
  overlay: true,
  icon: 'icon-help_outline',
  title: 'Are you sure?',
  zindex: 1000,
  buttons: [
    [
      '<button class="btn btn-sm me-2">OKAY</button>',
      function (instance, toast) {
        instance.hide({ transitionOut: 'fadeOut' }, toast, 'buttonName');
        actionCb(true);
      },
      true // true to focus
    ],
    [
      '<button class="btn btn-sm">CANCEL</button>',
      function (instance, toast) {
        instance.hide({ transitionOut: 'fadeOut' }, toast, 'buttonName');
        actionCb(false);
      }
    ]
  ],
  ...config
});

/**
 *
 * @param {{
 *   id: null,
 *   class: '',
 *   zindex: null,
 *   layout: 1,
 *   balloon: false,
 *   close: true,
 *   closeOnEscape: false,
 *   closeOnClick: false,
 *   target: '',
 *   targetFirst: true,
 *   timeout: 5000,
 *   rtl: false,
 *   animateInside: true,
 *   drag: true,
 *   pauseOnHover: true,
 *   resetOnHover: false,
 *   transitionIn: 'fadeInUp',
 *   transitionOut: 'fadeOut',
 *   transitionInMobile: 'fadeInUp',
 *   transitionOutMobile: 'fadeOutDown',
 *   theme: 'dark'|'light',
 *   color: ''|'blue'|'red'|'green'|'yellow',
 *   backgroundColor: '',
 *   icon: 'icon-person',
 *   iconText: '',
 *   iconColor: '',
 *   iconUrl: null,
 *   position: 'topCenter'|'bottomRight'|'bottomLeft'|'topRight'|'topLeft'|'topCenter'|'bottomCenter',
 *   progressBar: true,
 *   progressBarColor: 'rgb(0, 255, 184)',
 *   progressBarEasing: 'linear',
 *   image: '',
 *   imageWidth: 50,
 *   maxWidth: null,
 *   displayMode: 0,
 *   overlay: false,
 *   overlayClose: false,
 *   overlayColor: 'rgba(0, 0, 0, 0.6)',
 *   buttons: {},
 *   inputs: {},
 *   title: '',
 *   titleColor: '',
 *   titleSize: '',
 *   titleLineHeight: '',
 *   message: '',
 *   messageColor: '',
 *   messageSize: '',
 *   messageLineHeight: '',
 *   onOpening: (instance, toast) => {},
 *   onOpened: (instance, toast) => {},
 *   onClosing: (instance, toast, closed) => {},
 *   onClosed: (instance, toast, closed) => {}
 * }} config
 * @param actionCb : CallableFunction
 */
const deleteConfirmation = (config, actionCb) => toaster.warning({
  ...defaultConfig,
  timeout: 0,
  position: 'center',
  overlay: true,
  icon: 'icon-alert-triangle',
  iconColor: '#ffbf05',
  title: 'Sure to Delete ?',
  message: 'This will delete it permanently',
  messageSize: 17,
  titleLineHeight: 20,
  messageLineHeight: 20,
  progressBar: false,
  zindex: 1000,
  buttons: [
    [
      '<button class="btn btn-sm bg-danger me-2">DELETE</button>',
      function (instance, toast) {
        instance.hide({ }, toast);
        actionCb(true);
      },
      true
    ], // true to focus
    [
      '<button class="btn btn-sm">CANCEL</button>',
      function (instance, toast) {
        instance.hide({ transitionOut: 'fadeOut' }, toast);
        actionCb(false);
      }
    ]
  ],
  ...config
});

export default {
  toaster,
  info,
  primary,
  success,
  successAlt,
  warning,
  error,
  confirmation,
  deleteConfirmation
};

